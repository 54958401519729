<template>
  <el-popover :disabled="readonly"
      trigger="manual"
      placement="bottom"
      @show="show"
      :width="width"
      popper-class="hospital_search_popper"
      v-clickoutside="()=>visible=false"
      :value="visible">
      <div ref="popoverContainer" v-loading="isLoading">
        <ul class="hospital_search_list" v-if="optionList[0]">
          <li v-for="item in optionList"  :key="item.id"  @click="handleChange(item.id)">{{item.name}}</li>
        </ul>
        <div v-else class="text-align-center">无匹配数据</div>
      </div>
      <el-input
        slot="reference"
        ref="input"
        :readonly="readonly"
        :value="defaultValue"
        @focus="handleFocus"
        clearable
        @clear="clear"
        @input="handleInput"
        :placeholder="placeholder"
      >
      </el-input>
  </el-popover>
</template>
<script>
import CustomerApi from '@/api/customer';
import Clickoutside from 'element-ui/src/utils/clickoutside';
import _ from 'lodash';
export default {
  name: 'HOSPITAL_SELECTOR',
  directives: {
    Clickoutside,
  },
  props: {
    value: {
      type: String,
    },
    size: {
      type: String,
      default: 'small',
    },
    name: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '请选择医院',
    },
  },
  data () {
    return {
      visible: false,
      optionList: [],
      defaultValue: this.name,
      width: 100,
      isLoading: false,
    };
  },
  watch: {
    name: function (val) {
      if (val !== this.defaultValue) {
        this.defaultValue = val;
      }
    },
  },
  methods: {
    show () {
      this.visible = true;
      this.search();
    },
    clear () {
      this.defaultValue = '';
      this.$emit('input', null);
      this.$emit('update:name', null);
      this.$emit('change', {});
    },
    handleFocus () {
      if (this.defaultValue && this.defaultValue.length >= 1) {
        this.visible = true;
      } else {
        this.visible = false;
      }
    },
    handleInput (name) {
      this.defaultValue = name;
      this.$emit('input', null);
      this.$emit('update:name', name);
      this.$emit('change', {name});
      this.search();
      this.handleFocus();
    },
    getList () {
      if (this.defaultValue) {
        this.isLoading = true;
        CustomerApi.getHosipital({
          name: this.defaultValue,
          pageNum: 1,
          pageSize: 10,
          enable: true,
        }).then(res => {
          this.optionList = res.body || [];
        }).finally(() => {
          this.isLoading = false;
        });
      } else {
        this.optionList = [];
      }
    },
    handleChange (id) {
      let item = this.optionList.find(t => t.id === id);
      if (item) {
        this.$emit('input', item.id);
        this.$emit('update:name', item.name);
        this.$emit('change', item);
      }
    },
    calcPopoverWidth () {
      let rect = this.$refs.input.$el.getBoundingClientRect();
      this.width = rect.width;
    },
  },
  mounted () {
    this.calcPopoverWidth();
    this.search = _.debounce(this.getList, 500);
    window.addEventListener('resize', this.calcPopoverWidth);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calcPopoverWidth);
  },
};

</script>

<style lang="scss" >
.hospital_search_popper{

  .hospital_search_list {
    width: 100%;
    list-style: none;
    padding: 2px 0;
    margin: 0;
    box-sizing: border-box;
    max-height: 300px;
    overflow-y: auto;
    &>li {
      font-size: 14px;
      padding: 0 20px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #606266;
      height: 34px;
      line-height: 34px;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        color: #409EFF;
        font-weight: 700
      }
    }
}
}

</style>
